.aboutWrapper {
  background: url("./assets/background.png") center center/cover no-repeat;
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10%;
  font-family: "HelveticaBold";
}

.leftContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.leftContainer > img {
  width: 340px;
}
.leftContainer > p {
  margin-top: -10px;
  letter-spacing: 16px;
  color: rgba(255, 255, 255, 0.2);
  font-size: 25px;
  font-weight: 700;
}
.rightContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
}

.rightContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rightContainer > div img {
  width: 100px;
}

.rightContainer > div h1 {
  margin: 0;
  margin-top: 5px;
  color: #ff00a8;
  font-weight: 700;
  font-size: 90px;
}

.rightContainer > div h2 {
  margin: 0;
  color: white;
  font-size: 30px;
}

@media screen and (max-width: 1200px) {
  .rightContainer > div h1 {
    font-size: 70px;
  }

  .rightContainer > div h2 {
    font-size: 25px;
  }
}
@media screen and (max-width: 990px) {
  .aboutWrapper {
    gap: 3%;
  }
  .rightContainer {
    gap: 2%;
  }
}
@media screen and (max-width: 900px) {
  .aboutWrapper {
    flex-direction: column;
    height: fit-content;
    padding: 30px 0px;
  }
}

@media screen and (max-width: 530px) {
  .leftContainer {
    display: none;
  }
  .leftContainer > img {
    width: 320px;
  }
  .rightContainer {
    flex-direction: column;
    gap: 40px;
  }
  .rightContainer > div img {
    width: 75px;
  }
  .rightContainer > div h1 {
    font-size: 60px;
  }

  .rightContainer > div h2 {
    font-size: 25px;
  }
}
