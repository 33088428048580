.contactsWrapper {
  align-items: center;
  justify-content: center;
  margin: 0%;

  color: white;
  width: 100%;
}
.contactsWrapper > .headingTeam {
  font-size: 50px;
  color: white;
  display: flex;
  justify-content: center;
  margin: 0%;
}

.sponsorsLogoWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 50px;
  width: 100%;
  gap: 2vw;
}

.divSponsorsTire {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0%;
  margin: 0%;
  width: 70vw;
}

.companySponsorsDiv {
  display: flex;
  align-items: center;
  gap: 5%;
}

.companySponsorsDiv > a > img {
  max-width: 15vw;
  height: 6vw;
  object-fit: contain;
  cursor: pointer;
}
.twinkleSponsors {
  display: flex;
  background: transparent;
  align-self: center;
  justify-self: right;
}
.twinkleSponsors > img {
  width: 3.5vw;
}

.lineThree {
  height: 1px;
  width: 50%;
  background-color: #888888;
}

@media screen and (max-width: 630px) {
  .contactsWrapper > .headingTeam {
    font-size: 30px;
  }
  .divSponsorsTire {
    width: 80vw;
  }
  .companySponsorsDiv > a > img {
    max-width: 18vw;
  }
}

@media screen and (max-width: 500px) {
  .sponsorsLogoWrapper {
    gap: 2vh;
  }
  .divSponsorsTire {
    flex-direction: column-reverse;
    gap: 20px;
  }
  .twinkleSponsors > img {
    width: 6.5vw;
  }

  .lineThree {
    display: none;
  }
}
