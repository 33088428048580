.outerWrapper {
  width: 100%;
  height: fit-content;
  overflow: hidden;
}

.journeyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.journeyWrapper h1 {
  font-size: 50px;
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
.mapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 1300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapWrapper > .background {
  position: absolute;
  width: 100%;
  min-width: 1900px;
  height: 100%;
  z-index: 3000;
}
.timelineWrapper {
  position: absolute;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  padding-left: 0px;
  margin-top: -70px;
}
.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.circle {
  width: 30px;
  height: 30px;
  background-color: #e5bb7d;
  border-radius: 50%;
}
.contentContainer img {
  width: 43px;
  height: 43px;
  object-fit: contain;
}
.ptagWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ptagWrapper p {
  color: white;
  margin: 0;
  border-radius: 6.826px;
  background: rgba(255, 255, 255, 0.09);
  padding: 10px 20px;
  font-weight: 500;
}

.stickone {
  width: 10px;
  height: 140px;
  background-color: #e5bb7d;
  margin: -30px 0px -30px 10px;
}

.sticktwo {
  width: 40px;
  margin: -30px 0px 0px 10px;
  height: 100px;
  border-left: 10px solid #e5bb7d;
  border-bottom: 10px solid #e5bb7d;
}

.phaseone {
  margin: -50px 0px 0px 50px;
}
.stickthree {
  width: 250px;
  margin: -30px 0px 0px 60px;
  height: 110px;
  border-left: 10px solid #e5bb7d;
  border-bottom: 10px solid #e5bb7d;
}
.phasetwo {
  margin: -50px 0px 0px 310px;
}
.stickfour {
  width: 220px;
  margin: -30px 0px 0px 320px;
  height: 40px;
  border-left: 10px solid #e5bb7d;
  border-bottom: 10px solid #e5bb7d;
}
.stickfourSub {
  width: 10px;
  height: 105px;
  background-color: #e5bb7d;
  margin: -10px 0px -30px 540px;
}
.phasethree {
  margin: 0px 0px 0px 310px;
}
.stickfive {
  width: 10px;
  margin: -30px 0px 0px 540px;
  height: 90px;
  background-color: #e5bb7d;
}
.stickfiveSub {
  width: 10px;
  height: 140px;
  background-color: #e5bb7d;
  margin: -7px 0px -30px 563px;
  transform: rotate(-20deg);
}
.sticksix {
  width: 135px;
  margin: 22px 0px 0px 460px;
  height: 10px;
  background-color: #e5bb7d;
}
.phasefour {
  margin: -50px 0px 0px 230px;
}

@media screen and (max-width: 640px) {
  .mapWrapper {
    min-height: 1000px;
  }

  .mapWrapper > .background {
    min-width: 1000px;
  }

  .contentContainer {
    gap: 10px;
  }
  .circle {
    width: 25px;
    height: 25px;
  }
  .contentContainer img {
    width: 35px;
    height: 35px;
  }
  .ptagWrapper p {
    font-size: 14px;
    padding: 10px 15px;
    font-weight: 500;
  }
  .timelineWrapper {
    margin-top: -40px;
  }

  .stickone {
    width: 7px;
    height: 110px;
    margin: -33px 0px -33px 9px;
  }

  .sticktwo {
    width: 30px;
    margin: -30px 0px 0px 10px;
    height: 70px;
    border-left: 7px solid #e5bb7d;
    border-bottom: 7px solid #e5bb7d;
  }

  .phaseone {
    margin: -47px 0px 0px 40px;
  }
  .stickthree {
    width: 60px;
    margin: -33px 0px 0px 50px;
    height: 75px;
    border-left: 7px solid #e5bb7d;
    border-bottom: 7px solid #e5bb7d;
  }
  .phasetwo {
    margin: -45px 0px 0px 100px;
  }
  .stickfour {
    width: 175px;
    margin: -33px 0px 0px 110px;
    height: 40px;
    border-left: 7px solid #e5bb7d;
    border-bottom: 7px solid #e5bb7d;
  }
  .stickfourSub {
    width: 7px;
    height: 100px;
    margin: 0px 0px -30px 285px;
  }
  .phasethree {
    margin: -5px 0px 0px 105px;
  }
  .stickfive {
    width: 7px;
    margin: -33px 0px 0px 285px;
    height: 50px;
  }
  .stickfiveSub {
    width: 7px;
    height: 100px;
    margin: -7px 0px -30px 297px;
    transform: rotate(-15deg);
  }
  .sticksix {
    width: 75px;
    margin: 22px 0px 0px 235px;
    height: 7px;
  }
  .phasefour {
    margin: -45px 0px 0px 40px;
  }
}
@media screen and (max-width: 630px) {
  .journeyWrapper h1 {
    font-size: 30px;
  }
}
