.LocationCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 50px;
}
.locationWrapper {
  width: 100%;
  min-height: 500px;
  background: url("./assets/background.png") center center/cover no-repeat;
  display: flex;
  align-items: center;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  gap: 30px;
}
.infoContainer img {
  width: 340px;
}
.infosection {
  color: white;
  width: 300px;
}

.infosection h1 {
  margin: 0%;
  font-size: 35px;
}
.infosection p {
  font-size: 20px;
}
.atagButton {
  text-decoration: none;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px 20px;
  width: fit-content;
}

@media screen and (max-width: 400px) {
  .locationWrapper {
    justify-content: center;
  }
  .infoContainer {
    margin-left: 0%;
    gap: 20px;
  }
  .infoContainer img {
    width: 300px;
  }
}
.bottomWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  height: 190px;
}
.cardWrapper {
  display: flex;
  width: fit-content;
  font-size: 12px;
  color: white;
  gap: 10px;
  align-items: center;
  justify-content: center;
  border: 2px solid #2d2d2d;
  background-color: #000;
  padding: 8px 12px;
  border-radius: 8px;
  height: 190px;
}

.cardWrapper img {
  width: 90px;
}

.lineDiv {
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.15);
}

.contactWrapper {
  width: fit-content;
  border: 2px solid #2d2d2d;
  background-color: #000;
  padding: 8px 12px;
  border-radius: 8px;
  height: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.contactWrapper p {
  margin: 0;
}
.innerContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
}
.innerContentWrapper > div {
  display: flex;
  gap: 5px;
  align-items: baseline;
}

.innerContentWrapper a {
  text-decoration: none;
  color: white;
  font-weight: 500;
}

@media screen and (max-width: 590px) {
  .bottomWrapper {
    flex-direction: column;
    height: 380px;
  }
  .contactWrapper{
    width: 280px;
  }
}
