.verticalWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 6vh;
}
.verticalWrapper h1 {
  font-size: 50px;
  color: white;
  display: flex;
  justify-content: center;
  margin: 0%;
  margin-bottom: 3vh;

}
.contentContainer {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0px;
}

.contentContainer > div {
  width: 40%;
}

.verticalWrapper img {
  width: 100%;
  object-fit: contain;
}
@media screen and (max-width: 630px) {
  .verticalWrapper h1 {
    font-size: 30px;
  }
  .contentContainer > div {
    width: 80%;
  }
}
