.outerWrapper {
  width: 100%;
  height: fit-content;
  overflow: hidden;
}

.journeyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.journeyWrapper h1 {
  font-size: 50px;
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
.mapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 180vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapWrapper > .background {
  position: absolute;
  width: 100%;

  height: 100%;
  z-index: 3000;

  object-fit: cover;
}

.maskbg {
  position: absolute;
  width: 100%;

  height: 100%;
  z-index: 4000;
  min-height: 180vh;
  top: 0;
  left: 0;
}
.outerWrapper {
  width: 100%;
  height: fit-content;
  overflow: hidden;
}

.journeyWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.journeyWrapper h1 {
  font-size: 50px;
  color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}
.mapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 180vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapWrapper > .background {
  position: absolute;
  width: 100%;

  height: 100%;
  z-index: 3000;

  object-fit: cover;
}

.maskbg {
  position: absolute;
  width: 100%;

  height: 100%;
  z-index: 4000;
  min-height: 180vh;
  top: 0;
  left: 0;
}
.timelineWrapper {
  position: absolute;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  padding-left: 0px;
  margin-top: -40px;
}
/* .timelineWrapper {
  position: absolute;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 600px;
  height: 100%;
  padding-left: 0px;
  margin-top: -40px;
  background-color: rgba(255, 255, 255, 0.4);
}

.yellowCircle {
  width: 28px;
  height: 28px;
  background-color: #e5bb7d;
  border-radius: 50%;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.container .inner {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.container h3 {
  font-size: 20px;
  font-weight: 500;
  color: #e5bb7d;
  margin: 0;
}

.container p {
  margin: 0;
  color: white;
  font-weight: 300;
  font-size: 17.5px;
  font-family: "HelveticaLight";
}
.container img {
  width: 35px;
  height: 35px;
  object-fit: contain;
}
.first {
  margin-left: 98px;
}

.strip1 {
  height: 260px;
  width: 10px;
  background-color: #e5bb7d;
  margin-top: -33px;
  margin-left: 107px;
}
.strip1Sub {
  transform: rotate(95deg);
  width: 10px;
  height: 50px;
  background-color: #e5bb7d;
  margin-top: -20px;
  margin-left: 127px;
}

.second {
  margin-top: -58px;
  margin-left: 150px;
}
.strip2 {
  height: 20px;
  width: 10px;
  background-color: #e5bb7d;
  margin-top: -33px;
  margin-left: 160px;
}

.strip2Sub {
  height: 80px;
  width: 10px;
  background-color: #e5bb7d;
  transform: rotate(113deg);
  margin-top: -30px;
  margin-left: 193px;
  border-radius: 10px;
}
.strip2Sub2 {
  height: 60px;
  width: 10px;
  background-color: #e5bb7d;
  transform: rotate(5deg);
  margin-top: -28px;
  margin-left: 222px;
  border-radius: 3px;
}

.third {
  margin-top: -35px;
  margin-left: 211px;
}
.strip3 {
  height: 50px;
  width: 10px;
  background-color: #e5bb7d;
  transform: rotate(8deg);
  margin-top: -30px;
  margin-left: 214.5px;
  border-bottom-right-radius: 10px;
}
.strip3Sub {
  height: 50px;
  width: 125.5px;

  border-top: 10px solid #e5bb7d;
  border-right: 10px solid #e5bb7d;

  margin-top: -10px;
  margin-left: 212px;
  border-top-left-radius: 5px;
}
.four {
  margin-top: -25px;
  margin-left: 20px;
}
.strip4 {
  height: 70px;
  width: 30px;
  margin-top: -25px;
  margin-left: 328px;

  border-left: 10px solid #e5bb7d;
  border-bottom: 10px solid #e5bb7d;
}
.strip4Sub {
  height: 50px;
  width: 10px;

  border-left: 10px solid #e5bb7d;
}

.five {
  margin-top: -28px;
  margin-left: 22px;
}

.strip5 {
  width: 10px;
  height: 100px;
  background-color: #e5bb7d;
  margin-top: -15px;
}

.six {
  margin-top: -28px;
}
.strip6 {
  margin-top: -15px;
  width: 150px;
  height: 80px;
  border-right: 10px solid #e5bb7d;
  border-bottom: 10px solid #e5bb7d;
}
.strip6Sub {
  width: 10px;
  height: 80px;
  background-color: #e5bb7d;
}
.seven {
  margin-top: -28px;
} */

@media screen and (max-width: 840px) {
  .mapWrapper {
    min-height: 1500px;
  }

  .mapWrapper > .background {
    min-width: 1000px;
  }
  .maskbg {
    min-height: 1200px;
  }


}
@media screen and (max-width: 640px) {
  .mapWrapper {
    min-height: 1000px;
  }

  .mapWrapper > .background {
    min-width: 1000px;
  }
  .maskbg {
    min-height: 1000px;
  }

  .timelineWrapper {
    margin-top: -40px;
  }
}
@media screen and (max-width: 630px) {
  .journeyWrapper h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 410px) {
  .mapWrapper {
    min-height: 850px;
  }
  .maskbg {
    min-height: 850px;
  }
  .mapWrapper > .background {
    min-width: 800px;
  }
}
