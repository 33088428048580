.prizeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  color: aliceblue;
  font-size: 15px;
  text-align: center;
}
.amountWrapper {
  display: flex;
  width: 80%;
  justify-content: space-around;
  color: white;
}

.amountWrapper h2 {
  font-size: 2.7vw;
  margin: 0;
  color: #ffffff;
}
.amountWrapper h1 {
  font-size: 4vw;
  margin: 0;
}
.first {
  background: var(
    --Verticals,
    linear-gradient(180deg, #ff46c0 22.22%, #ff97dc 83.18%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.second {
  background: linear-gradient(180deg, #fbd8a3 0%, #dab277 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.third {
  background: linear-gradient(180deg, #3d2500 0%, #dab277 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 700px) {
  .amountWrapper h2 {
    font-size: 3vw;
  }
  .amountWrapper h1 {
    font-size: 4vw;
  }
  .prizeWrapper {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .amountWrapper {
    flex-wrap: wrap;
    gap: 10px;
    width: 90%;
  }
  .amountWrapper > div {
    width: 45%;
  }
  .amountWrapper h2 {
    font-size: 18px;
  }
  .amountWrapper h1 {
    font-size: 24px;
  }
}
