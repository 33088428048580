.verticalWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 6vh;
}
.verticalWrapper h1 {
  font-size: 50px;
  color: white;
  display: flex;
  justify-content: center;
  margin: 0%;
  margin-bottom: 3vh;
}
.contentContainer {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0px;
}

.contentContainer > div {
  width: 40%;
}

.verticalWrapper img {
  width: 100%;
  object-fit: contain;
}
@media screen and (max-width: 630px) {
  .verticalWrapper h1 {
    font-size: 30px;
  }
  .contentContainer > div {
    width: 80%;
  }
}

.verticalWrapper .contentContainer a {
  padding: 10px 20px;
  color: white;
  border: 2px solid white;
  border-radius: 10px;
}
.verticalWrapper .contentContainer a:hover {
  color: #FFD597;
  border-color: #FFD597;
}
.verticalWrapper .problem{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.submitLink{
  margin-top: -20px;
  margin-bottom: 30px;
  color: white;
  display: flex;
  border: 5px solid white;
  padding: 0px 0px 0px 20px;
  align-items: center;
  border-radius: 10px;
}
.submitLink p{
  padding-right: 20px;
  margin: 0;
}

.submitLink a{
  color: black;
  background-color: white;
  cursor: pointer;
  display: flex;
  height: 100%;
  padding:15px 20px;
  border-radius: 5px 0px 0px 5px;
  text-decoration: none;
}